<template>
  <div>
    <b-row>
      <b-col>
        <filter-list />
      </b-col>
      <b-col class="text-right">
        <service-button :current="'filter-service'" />
      </b-col>
    </b-row>

    <!-- 상단 버튼부 -->
    <b-row class="match-height">
      <b-col>
        <filter-check-list-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import ServiceButton from "../../layouts/button/ServiceButton.vue"
import FilterCheckListTable from "./FilterCheckListTable.vue";
import FilterList from "./FilterList.vue";

export default {
  components: {
    FilterCheckListTable,
    BRow,
    BCol,
    ServiceButton,
    FilterList,
  },
  computed: {},
  mounted() {
    // gis delete..
  }
};
</script>

<style></style>
