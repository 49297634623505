<template>
  <div class="body-style">
    <!--    <div class="">
      <b-button
        variant="outline-dark"
        size="sm"
        @click="opnsvcPopupOpen()"
      >
        업종 검색
      </b-button>
    </div>-->

    <b-row>
      <b-col>
        <b-form-group label="사업장명">
          <b-form-input
            v-model="bplcNm"
            placeholder="사업장명 입력"
            style="border: 2px solid #ccc;"
            @keyup.enter="searchOpnsvc"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label=" "
          class="align-middle"
        >
          <b-button
            variant="outline-dark"
            class="ml-1"
            @click="searchOpnsvc()"
          >
            <feather-icon icon="SearchIcon" />
            검색
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-alert
          variant="primary-dark"
          small
          show
        >
          <div
            class="alert-body"
            style="background-color: #EFEFEF;"
          >
            <span><strong>에너지 사용량 정보</strong>가 있는 인허가 건물만 검색됩니다.</span>
          </div>
        </b-alert>
        <b-overlay
          class="text-primary"
          :show="isBusy"
          rounded="xl"
        >
          <b-table-simple
            hover
            responsive
            bordered
            sticky-header="450px"
            class="rounded-bottom mb-0"
            style="white-space: nowrap;"
          >
            <b-thead head-variant="dark">
              <b-tr class="test-center align-middle">
                <b-th class="text-center align-middle p-1">
                  <b-button
                    size="sm"
                    variant="outline-light"
                    @click="searchOpnsvcAll()"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class=""
                    />
                  </b-button>
                </b-th>
                <b-th class="text-center align-middle">
                  업종명
                </b-th>
                <b-th class="text-center align-middle">
                  사업장명
                </b-th>
                <b-th class="text-center align-middle">
                  업태명
                </b-th>
                <b-th class="text-center align-middle">
                  지번주소
                </b-th>
                <b-th class="text-center align-middle">
                  도로명주소
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="opnsvcDataList.length > 0">
              <b-tr
                v-for="(data, index) in opnsvcDataList"
                :key="index"
              >
                <b-td class="text-left align-middle p-1">
                  <b-button
                    size="sm"
                    variant="outline-dark"
                    @click="selectOpnsvc(data)"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class=""
                    />
                  </b-button>
                </b-td>
                <b-td class="text-left align-middle">
                  {{ data.opnsvcNm }}
                </b-td>
                <b-td class="text-left align-middle">
                  {{ data.bplcNm }}
                </b-td>
                <b-td class="text-left align-middle">
                  {{ data.uptaeNm }}
                </b-td>
                <b-td class="text-left align-middle">
                  {{ data.sitewhlAddr }}
                </b-td>
                <b-td class="text-left align-middle">
                  {{ data.rdnwhlAddr }}
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td
                  colspan="27"
                  variant="light"
                  class="text-center align-middle"
                >검색된 데이터가 없습니다.
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay
          class="text-primary"
          :show="isBusyBadge"
          rounded="xl"
        >
          <b-card-code
            title="▶ 선택한 업종"
            class="mt-2"
            style="max-height: 300px; overflow-y: scroll"
          >
            <b-badge
              v-for="(code, index) in selectedOpnsvc"
              :key="index"
              class="mr-1"
              variant="light-dark"
            >{{ code.bplcNm }}
              <feather-icon
                icon="DeleteIcon"
                class="mr-25"
                style="cursor:pointer;"
                @click="deleteOpnsvcInfo(code)"
              />
            </b-badge>
          </b-card-code>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  VBModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BBadge, BAlert,
} from "bootstrap-vue";
import { utils } from "@/devUtils/commUtils";

export default {
  components: {
    BCardCode,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
    BAlert,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    openSvcId: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalType: false,

      // 사업장명
      bplcNm: "",

      // 선택한 인허가건물
      selectedOpnsvc: [],

      isBusy: false,
      isBusyBadge: false,

      cnt: 0,
    };
  },
  computed: {
    ...mapGetters({
      opnsvcDataList: "filter/getOpnsvcList",
      resultInfo: "filter/getResult",

      filterFetchedInfo: "filter/getFilterFetchedInfo",
    }),
  },
  watch: {
    openSvcId(newValue) {
      if (this.cnt === 0) { // 프롭스 넘어온 변경감지값은 1번만 실행
        this.isBusyBadge = true;
        this.filterFetchedInfo.openSvcId = newValue;
        this.$store.dispatch("filter/FETCH_OPNSVC_LOAD_LIST", this.filterFetchedInfo).then(() => {
          this.selectedOpnsvc = this.$store.state.filter.opnsvcLoadList.opnsvcList;
          this.isBusyBadge = false;
        }).catch(() => {
          console.error('오류')
        })
      }
      // eslint-disable-next-line no-plusplus
      this.cnt++;
    },
  },
  created() {
    // 검색한 업종 정보 초기화
    this.$store.commit("filter/SET_OPNSVC_LIST", []);

    // 선택된 업종 정보 초기화
    this.$store.commit("filter/SET_INIT_SELECTED_COMM_CODE", {
      key: "OPEN_SVC_ID",
      value: [],
    });

    // 팝업에서 사용하는 변수들 초기화
    this.bplcNm = "";
    this.selectedOpnsvc = [];
  },
  methods: {
    // 팝업 오픈
    opnsvcPopupOpen() {
      this.modalType = !this.modalType;

      // 검색한 업종 정보 초기화
      this.$store.commit("filter/SET_OPNSVC_LIST", []);

      // 선택된 업종 정보 초기화
      this.$store.commit("filter/SET_INIT_SELECTED_COMM_CODE", {
        key: "OPEN_SVC_ID",
        value: [],
      });

      // 팝업에서 사용하는 변수들 초기화
      this.bplcNm = "";
      this.selectedOpnsvc = [];
    },

    // 인허가 건물 검색
    async searchOpnsvc() {
      this.isBusy = true;
      const bplcNm = this.bplcNm;

      await this.$store.dispatch("filter/FETCH_OPNSVC_LIST", { bplcNm });

      this.isBusy = false;
      if (!this.resultInfo.resultFlag) {
        utils.fnAlert(this, this.resultInfo.resultMsg);
      }
    },

    // 업종 전체 선택
    searchOpnsvcAll() {
      if (this.opnsvcDataList.length > 0) {
        if (this.selectedOpnsvc.length === this.opnsvcDataList.length) {
          this.selectedOpnsvc = [];
        } else {
          this.selectedOpnsvc = Array.prototype.slice.call(this.opnsvcDataList);
          this.confirmOpnsvcInfo()
        }
      }
    },
    // 업종 선택
    selectOpnsvc(obj) {
      const selected = this.selectedOpnsvc;

      // 동일한 데이터를 선택하지 않았을 때만 push함.
      if (!selected.includes(obj)) {
        this.selectedOpnsvc.push(obj);
      }
      this.confirmOpnsvcInfo();
    },

    // 업종 삭제
    deleteOpnsvcInfo(obj) {
      console.log('delete');
      const selected = this.selectedOpnsvc;

      if (selected.includes(obj)) {
        selected.splice(selected.indexOf(obj), 1);
      }
    },

    // 확인 버튼 클릭시
    confirmOpnsvcInfo() {
      this.$store.commit("filter/SET_INIT_FILTER_FETCHED_INFO", {
        key: "openSvcId",
        value: this.selectedOpnsvc,
      });
    },
  },
};
</script>
<style scoped>
.body-style {
  font-family: Helvetica Neue, Arial, sans-serif;
}

input {
  height: auto !important;
}
</style>
