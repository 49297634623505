<template>
  <section id="filterCheckListTable">
    <b-card-actions
      title="건축물 대장 정보"
      action-collapse
    >
      <b-row class="mt-2">
        <b-col
          xl="12"
          md="12"
        >
          <b-table-simple
            hover
            small
            stacked
            bordered
            class="rounded-bottom mb-0"
          >
            <b-tbody>
              <b-tr>
                <b-th class="text-center text-white bg-dark">
                  건축물 대장 정보 필터
                </b-th>
                <b-td stacked-heading="대지점유방식">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.PLAT_OCP_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.platOcpCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="소유방식">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.REGSTR_GB_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.regstrGbCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="소유자 유형">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.OWNER_TYPE_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.ownerTypeCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="지역구분 (예정)">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.KMA_AREA_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.kmaAreaCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                      @click="sidoShowYn()"
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="시도">
                  <span>
                    <b-form-radio
                      v-for="code in sidoList"
                      :key="code.sidoId"
                      v-model="filterFetchedInfo.sidoId"
                      :value="code"
                      class="checkbox-inline"
                      plain
                      @input="sidoChange($event)"
                    >
                      {{ code.sidoNm }}
                    </b-form-radio>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="시군구">
                  <span>
                    <b-form-checkbox
                      v-for="code in sigunguList"
                      :key="code.sigunguId"
                      v-model="filterFetchedInfo.sigunguId"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.sigunguNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="용도구분">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.PURPS_GB_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.purpsGbCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="주용도">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.MAIN_PURPS_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.mainPurpsCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="세부용도">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.DTLD_USE_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.dtldUseCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="대표용도">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.TYPCL_USE_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.typclUseCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="층별용도 통일성">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.UNF_USE_FLR"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.unfUseFlr"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="단열기준">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.INSLT_ST_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.insltStCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="연식기준">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.ANUAL_ST_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.anualStCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td stacked-heading="연면적구분">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.TOTAREA_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.totareaCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple
            hover
            small
            stacked
            bordered
            class="rounded-bottom mb-0"
          >
            <b-tbody>
              <b-tr>
                <b-th class="text-center text-white bg-dark">
                  건축물 운영 정보 필터
                </b-th>
                <b-td>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label=""
                        label-for="useYyCd"
                      >
                        <filter-opnsvc-popup-renew :open-svc-id="filterFetchedInfo.openSvcId" />
                      </b-form-group>

                      <!--                      <b-card
                                              v-if="filterInfo.OPEN_SVC_ID.length > 0"
                                              :border-variant="'dark'"
                                              :size="'sm'"
                                              class="shadow-none mt-1 mb-1 align-content-center"
                                              style="max-height: 300px; overflow-y: scroll"
                                              f-g
                                            >
                                              <b-badge
                                                v-for="(code, index) in filterInfo.OPEN_SVC_ID"
                                                :key="index"
                                                class="mr-1"
                                                variant="light-dark"
                                              >{{ code.bplcNm }}
                                              </b-badge>
                                            </b-card>-->
                    </b-col>
                  </b-row>
                </b-td>
                <b-td stacked-heading="연간 운영 일수">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.ANUAL_OPDYS_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.anualOpdysCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code }}
                    </b-form-checkbox>
                  </span>
                </b-td>
                <b-td stacked-heading="일평균 운영시간">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.AVRG_OPHRS_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.avrgOphrsCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple
            hover
            small
            stacked
            bordered
            class="rounded-bottom mb-0"
          >
            <b-tbody>
              <b-tr>
                <b-th class="text-center text-white bg-dark">
                  에너지 사용량 필터
                </b-th>
                <b-td stacked-heading="소비연도코드">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.USE_YY_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.useYyCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
                <b-td stacked-heading="에너지원">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.ENGY_KIND_CD"
                      :key="code.cdId"
                      v-model="filterFetchedInfo.engyKindCd"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
                <b-td stacked-heading="사용용도">
                  <span>
                    <b-form-checkbox
                      v-for="code in filterCommCodeData.CLSF_KIND_CD"
                      :key="code.cdId"
                      v-model="filterInfo.CLSF_KIND_CD"
                      :value="code"
                      class="checkbox-inline"
                      plain
                    >
                      {{ code.cdNm }}
                    </b-form-checkbox>
                  </span>
                </b-td>
              </b-tr>

            </b-tbody>
          </b-table-simple>
          <b-table-simple
            hover
            small
            stacked
            bordered
            class="rounded-bottom mb-0"
          >
            <b-tbody>
              <b-tr>
                <b-th class="text-center text-white bg-dark">
                  시뮬레이션 데이터 필터
                </b-th>
                <b-td stacked-heading="" />
              </b-tr>

            </b-tbody>
          </b-table-simple>
          <b-table-simple
            hover
            small
            stacked
            bordered
            class="rounded-bottom mb-0"
          >
            <b-tbody>
              <b-tr>
                <b-th class="text-center text-white bg-dark">
                  소비수준 판단 지표 필터
                </b-th>
                <b-td stacked-heading="" />
              </b-tr>

            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          xl="6"
          md="6"
        >

          <div class="form-label-group">
            <b-form-input
              id="filterName"
              ref="filterName"
              v-model="filterFetchedInfo.filterNm"
              placeholder="저장할 필터명을 입력하세요."
              autocomplete="off"
              style="border: 2px solid #ccc;height: 40px"
            />
            <label for="filterName">저장할 필터명을 입력하세요.</label>
          </div>
        </b-col>
        <b-col
          xl="6"
          md="6"
        >
          <div>
            <b-button
              block
              variant="danger"
              @click="saveFilter()"
            >
              저장
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-modal
      v-model="modalType"
      size="xl"
      centered
      title="필터조회내역"
      ok-title="확인"
      ok-only
    >
      <b-overlay
        class="text-primary"
        :show="show"
        rounded="xl"
      >
        <div>
          <filter-static-summary-table-renew />
          <filter-box-plot-chart-renew />
        </div>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BRow,
  BCol,
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BTbody,
  BFormCheckbox,
  BFormGroup,
  BButton,
  BFormRadio,
  BFormInput,
  BModal,
  VBModal,
  BOverlay,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { utils } from "@/devUtils/commUtils";
import FilterStaticSummaryTableRenew from "./FilterStaticSummaryTableRenew.vue";
import FilterOpnsvcPopupRenew from "./FilterOpnsvcPopupRenew.vue";
import FilterBoxPlotChartRenew from "./FilterBoxPlotChartRenew.vue";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormCheckbox,
    BFormGroup,
    FilterOpnsvcPopupRenew, // 업종 검색 팝업
    FilterStaticSummaryTableRenew,
    FilterBoxPlotChartRenew,
    BButton,
    BFormRadio,
    BFormInput,
    BModal,
    BOverlay,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      modalType: false,
    }
  },
  computed: {
    ...mapGetters({
      filterCommCodeData: "filter/getFilterCommCodeData",
      filterInfo: "filter/getSaveFilterInfo",
      sidoList: "filter/getSidoList",
      sigunguList: "filter/getSigunguList",
      // resultInfo: "filter/getResult",

      // 저장된 필터 리스트
      filterFetchedInfo: "filter/getFilterFetchedInfo",
      show: "filter/getShowOverlay",
    }),
  },
  mounted() {
    // console.log(this.$store.state.filterFetchedInfo)
  },
  created() {
    // 저장할 필터 정보 초기화
    this.$store.commit("filter/SET_RESET_SAVE_FILTER_INFO");

    // 공통코드 불러오기
    this.$store.dispatch("filter/FETCH_COMM_CODE_RENEW_DATA");

    // 시도 정보 불러오기
    this.$store.dispatch("filter/FETCH_SIDO_LIST");
  },
  updated() {
  },
  methods: {
    saveFilter() {
      utils.fnConfirm(this, "저장하시겠습니까?", "설정된 필터 조건들이 저장됩니다.")
        .then(async (res) => {
          if (res.value) { // 사용자가 확인 눌럿을 경우
            // 필수 조건 체크(저장 필터명에 값이 있어야 함)
            if (this.filterFetchedInfo.filterNm === "" || this.filterFetchedInfo.filterNm === undefined) {
              utils.fnAlert(this, "저장할 필터명을 입력해주세요.");
            }

            // 필터 정보 저장
            await this.$store.dispatch("filter/SAVE_FILTER_RENEW_INFO", this.filterFetchedInfo);
            const resFlag = await this.$store.getters["filter/getResult"];
            utils.fnAlert(this, resFlag.resultMsg);
          }

          this.modalType = !this.modalType;

          // 저장된 필터명으로 저장된 데이터 로드
          await this.$store.dispatch("filter/FETCH_FILTER_LOAD_DATA", this.filterFetchedInfo);
          await this.$store.dispatch("filter/FETCH_COMM_CODE_DATA_SUMMARY");
        })
    },
    // 시도 변경시 시군구 데이터 조회
    sidoChange(sidoId) {
      if (sidoId.length !== 0) {
        // 선택된 값이 있는 경우 sigungu 데이터 조회
        this.$store.dispatch("filter/FETCH_SIGUNGU_LIST", sidoId.sidoId);
      } else {
        // 선택된 값이 없는 경우 선택된 sigungu 초기화
        this.$store.dispatch("filter/FETCH_SIGUNGU_LIST", null);
      }
    },
  },

};
</script>

<style scoped>
.table.b-table.b-table-stacked > tbody > tr > [data-label]::before {
  width: 25% !important;
  border-right: 1px solid lightgray !important;
}

.checkbox-inline {
  display: inline-block;
  padding: 0 10px 0 0;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
